import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../Loader/Loader";
import coverImg from "../../images/cover_not_found.jpg";
import "./BookDetails.css";
import {FaArrowLeft} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import config from '../../aws-exports';
import { BookContext } from '../../context/books';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const BookDetails = () => {
    const {idDet} = useParams();
    const [loading, setLoading] = useState(false);
    const [book, setBook] = useState(null);
    const navigate = useNavigate();
    const { getBook, idioma } = useContext(BookContext);

    useEffect(() => {
        setLoading(true);

        function formarFecha (dia, mes) {
            let mesEscrito = "";
            switch(idioma) {
                case "español":
                switch (mes) {
                    case 1: mesEscrito = "Enero"; break;
                    case 2: mesEscrito = "Febrero"; break;
                    case 3: mesEscrito = "Marzo"; break;
                    case 4: mesEscrito = "Abril"; break;
                    case 5: mesEscrito = "Mayo"; break;
                    case 6: mesEscrito = "Junio"; break;
                    case 7: mesEscrito = "Julio"; break;
                    case 8: mesEscrito = "Agosto"; break;
                    case 9: mesEscrito = "Septiembre"; break;
                    case 10: mesEscrito = "Octubre"; break;
                    case 11: mesEscrito = "Noviembre"; break;
                    case 12: mesEscrito = "Diciembre"; break;
                    default: break;
                }
                if (dia === 0) {return mesEscrito} 
                    else { return dia + " de " + mesEscrito}
                case "english":
                    switch (mes) {
                        case 1: mesEscrito = "January"; break;
                        case 2: mesEscrito = "February"; break;
                        case 3: mesEscrito = "March"; break;
                        case 4: mesEscrito = "April"; break;
                        case 5: mesEscrito = "May"; break;
                        case 6: mesEscrito = "June"; break;
                        case 7: mesEscrito = "July"; break;
                        case 8: mesEscrito = "August"; break;
                        case 9: mesEscrito = "September"; break;
                        case 10: mesEscrito = "October"; break;
                        case 11: mesEscrito = "November"; break;
                        case 12: mesEscrito = "December"; break;
                        default: break;
                    }
                    if (dia === 0) {return mesEscrito} 
                        else { return mesEscrito + " " + dia}
                default:
                break;
            }
        }

        async function getBookDetails(){
            try{
                const data = await getBook(idDet);
                if (data){
                    const {titulo, portada, autor, pais, nacautor, contautor, editorial, tiraje, paginas, comentario, momento, diceautor, anoedicion, mesedicion, diaedicion} = data;
                    const newBook = {
                        titulo: titulo,
                        autor: autor,
                        pais: pais,
                        nacautor: nacautor,
                        contautor: contautor,
                        editorial: editorial,
                        tiraje: tiraje,
                        paginas: paginas,
                        cover_img: formarPortada(portada) ? `https://${bucket}.s3.${region}.amazonaws.com/images/${formarPortada(portada)}`: coverImg,
                        diaymes: formarFecha(diaedicion, mesedicion),
                        anoedicion: anoedicion,
                        comentario: comentario ? comentario : "No hay comentario",
                        momento: momento ? momento : "No hay momento",
                        diceautor: diceautor ? diceautor : "No hay comentario del autor",
                    };
                    setBook(newBook);
                } else {
                    setBook(null);
                }
                setLoading(false);
            } catch(error){
                console.log(error);
                setLoading(false);
            }
        }
        getBookDetails();
    }, [getBook, idDet, idioma]);

    function formarPortada (cover) {
        let agregar = "g.jpg";
        let buscar = ".jpg";
        let gr1 = cover.split(buscar);
        return gr1[0] + agregar;
    }

    if(loading) return <Loading />;
    switch (idioma) {
      case "español":
    return (
        <section className='book-details'>
            <div className="container">
                <button type='button' className="flex flex-c back-btn" onClick={() => { setBook(null); navigate("/book"); } }>
                    <FaArrowLeft size = {22} />
                    <span className="fs-18 fw-6">Atrás</span>
                </button>

                <div className='book-details-content grid'>
                    <div className='book-details-img'>
                        <img src = {book?.cover_img} alt = "cover img" />
                    </div>
                    <div className='book-details-info'>
                        <div className='book-details-item title'>
                            <span className='fw-6 fs-24'>{book?.titulo}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Fecha de edición: </span>
                           <span className='text-italic'>{book?.diaymes + " de " + book?.anoedicion}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>País de edición: </span>
                           <span className='text-italic'>{book?.pais}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Autor: </span>
                           <span className='text-italic'>{book?.autor}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Nacionalidad del Autor: </span>
                           <span className='text-italic'>{book?.nacautor}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Contacto del Autor: </span>
                           <span className='text-italic'>{book?.contautor}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Editorial: </span>
                           <span className='text-italic'>{book?.editorial}</span>
                        </div>                                                
                        <div className='book-details-item'>
                            <span className='fw-6'>Paginas: </span>
                           <span className='text-italic'>{book?.paginas}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Tiraje: </span>
                           <span className='text-italic'>{book?.tiraje}</span>
                        </div>                        
                        <div className='book-details-item description'>
                        <span className='fw-6 fs-12'>Comentario: </span>
                        <span className='text-italic fw-3 fs-12' dangerouslySetInnerHTML={{ __html: book?.comentario }}></span>
                        </div>
                        <div className='book-details-item description'>
                        <span className='fw-6 fs-12'>Momento: </span>
                        <span className='text-italic fw-3 fs-12' dangerouslySetInnerHTML={{ __html: book?.momento }}></span>
                        </div>
                        <div className='book-details-item description'>
                        <span className='fw-6 fs-12'>Dice el autor: </span>
                        <span className='text-italic fw-3 fs-12' dangerouslySetInnerHTML={{ __html: book?.diceautor }}></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
  case "english":
    return (
        <section className='book-details'>
            <div className="container">
                <button type='button' className="flex flex-c back-btn" onClick={() => { setBook(null); navigate("/book"); } }>
                    <FaArrowLeft size = {22} />
                    <span className="fs-18 fw-6">Back</span>
                </button>

                <div className='book-details-content grid'>
                    <div className='book-details-img'>
                        <img src = {book?.cover_img} alt = "cover img" />
                    </div>
                    <div className='book-details-info'>
                        <div className='book-details-item title'>
                            <span className='fw-6 fs-24'>{book?.titulo}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Edition's date: </span>
                           <span className='text-italic'>{book?.diaymes + ", " + book?.anoedicion}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Edition's country: </span>
                           <span className='text-italic'>{book?.pais}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Author: </span>
                           <span className='text-italic'>{book?.autor}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Author's nationality: </span>
                           <span className='text-italic'>{book?.nacautor}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Author's contact: </span>
                           <span className='text-italic'>{book?.contautor}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Publisher: </span>
                           <span className='text-italic'>{book?.editorial}</span>
                        </div>                                                
                        <div className='book-details-item'>
                            <span className='fw-6'>Pages: </span>
                           <span className='text-italic'>{book?.paginas}</span>
                        </div>
                        <div className='book-details-item'>
                            <span className='fw-6'>Printings: </span>
                           <span className='text-italic'>{book?.tiraje}</span>
                        </div>                        
                        <div className='book-details-item description'>
                        <span className='fw-6 fs-12'>Comment: </span>
                        <span className='text-italic fw-3 fs-12' dangerouslySetInnerHTML={{ __html: book?.comentario }}></span>
                        </div>
                        <div className='book-details-item description'>
                        <span className='fw-6 fs-12'>Unforgettable moment: </span>
                        <span className='text-italic fw-3 fs-12' dangerouslySetInnerHTML={{ __html: book?.momento }}></span>
                        </div>
                        <div className='book-details-item description'>
                        <span className='fw-6 fs-12'>The author says: </span>
                        <span className='text-italic fw-3 fs-12' dangerouslySetInnerHTML={{ __html: book?.diceautor }}></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
   default:
        break;
}
}

export default BookDetails;