import React, { useContext } from "react";
import { BookContext } from "../../context/books";
import Revista from '../RevistasList/Revista';
import Loading from "../Loader/Loader";
import "./RevistasList.css";


const RevistasList = () => {
    const {revistas, loading, resultTitle } = useContext(BookContext);

    if(loading) return <Loading />;

    revistas.forEach(item => {delete item.tituloMin; delete item.autorMin;});

    return (
        <section className='revistaslist'>
            <div className="container">
                <h2 className="result">{resultTitle}</h2>
            </div>
            <div className="revistaslist-content grid">
                {revistas.slice(0, 300).map((item, index) => {
                    return (
                        <Revista key = {index} {... item} />
                    )
                })}
            </div>
        </section>
    )
}

export default RevistasList;