import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../Loader/Loader";
import coverImg from "../../images/cover_not_found.jpg";
import "./GuiaDetails.css";
import {FaArrowLeft} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import config from '../../aws-exports';
import { BookContext } from '../../context/books';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const GuiaDetails = () => {
    const {idDet } = useParams();
    const [loading, setLoading] = useState(false);
    const [guia, setGuia] = useState(null);
    const navigate = useNavigate();
    const { getGuia, idioma } = useContext(BookContext);

    useEffect(() => {
        setLoading(true);
        async function getBookDetails(){
            try{
                const data = await getGuia(idDet);
                if (data){
                    const {titulo, institucion, nomfoto} = data;
                    const newGuia = {
                        titulo: titulo,
                        institucion: institucion,
                        cover_img: formarPortada(nomfoto) ? `https://${bucket}.s3.${region}.amazonaws.com/images/${formarPortada(nomfoto)}`: coverImg,
                    };
                    setGuia(newGuia);
                } else {
                    setGuia(null);
                }
                setLoading(false);
            } catch(error){
                console.log(error);
                setLoading(false);
            }
        }
        getBookDetails();
    }, [getGuia, idDet]);

    function formarPortada (cover) {
        let agregar = "g.jpg";
        let buscar = ".jpg";
        let gr1 = cover.split(buscar);
        return gr1[0] + agregar;
    }

    if(loading) return <Loading />;
    switch (idioma) {
      case "español":
    return (
        <section className='guia-details'>
            <div className="container">
                <button type='button' className="flex flex-c back-btn" onClick={() => { setGuia(null); navigate("/guia") } }>
                    <FaArrowLeft size = {22} />
                    <span className="fs-18 fw-6">Atrás</span>
                </button>

                <div className='guia-details-content grid'>
                    <div className='guia-details-img'>
                        <img src = {guia?.cover_img} alt = "cover img" />
                    </div>
                    <div className='guia-details-info'>
                        <div className='guia-details-item title'>
                            <span className='fw-6 fs-24'>{guia?.titulo}</span>
                        </div>
                        <div className='guia-details-item'>
                            <span className='fw-6'>Institucion: </span>
                           <span className='text-italic'>{guia?.institucion}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    case "english":
        return (
            <section className='guia-details'>
                <div className="container">
                    <button type='button' className="flex flex-c back-btn" onClick={() => { setGuia(null); navigate("/guia") } }>
                        <FaArrowLeft size = {22} />
                        <span className="fs-18 fw-6">Back</span>
                    </button>
    
                    <div className='guia-details-content grid'>
                        <div className='guia-details-img'>
                            <img src = {guia?.cover_img} alt = "cover img" />
                        </div>
                        <div className='guia-details-info'>
                            <div className='guia-details-item title'>
                                <span className='fw-6 fs-24'>{guia?.titulo}</span>
                            </div>
                            <div className='guia-details-item'>
                                <span className='fw-6'>Institution: </span>
                               <span className='text-italic'>{guia?.institucion}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
        default:
            break;
    }
}

export default GuiaDetails;