import React from "react";
import { CarrProvider } from '../../context/carruselContext';
import Navbar from '../Navbar/Navbar';
import Shelfbar from '../Shelfbar/Shelfbar';
import ExtrasMenu from '../ExtrasMenu/ExtrasMenu';
import Buscador from '../Buscador/Buscador';
import Amigos from '../Amigos/Amigos';
import Carrusel from '../Carrusel/Carrusel';
import './Header.css';

const Header = () => {
    return (
        <div className='holder'>
            <header className='header'>
                <Navbar />
                <Shelfbar />
            </header>
                <div id="boxgeneral" className="wrapper">
                        <div id="buscador">
                            <Buscador />
                        </div>
                        <div id="Extras" className="extras">
                            <ExtrasMenu />
                        </div>
                        <div id="amigos" className="amigs">
                            <Amigos />
                        </div>
                        <CarrProvider>
                        <div id="carrusel" className="carrusel">
                            <Carrusel />
                        </div>
                        </CarrProvider>    
                </div>
        </div>
    )
}

export default Header;