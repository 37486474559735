import React, { useContext } from "react";
import SearchForm from '../SearchForm/SearchForm';
import { BookContext } from "../../context/books";
import './Buscador.css';


const Buscador = () => {
    const {criterio, setCriterio, idioma} = useContext(BookContext);

  switch (idioma) {
    case "español":
    return (
        <nav className='buscador' id = "buscador">
            <div className="buscador-subtitle">
                <span>Buscador</span>
            </div>
            <div className="buscador-collapse">
                <ul className="buscador-nav">
                    <li className="us-form center">
                        Buscar por:
                            <select
                                name="criterio"
                                type="criterio"
                                placeholder={criterio === "bookname" ? "Nombre del libro" : "Nombre del autor"}
                                value={criterio}
                                onChange={(e) => setCriterio( e.target.value )}
                                required
                            >
                                <option value="bookname">Nombre del libro</option>
                                <option value="authorname">Nombre del autor</option>
                            </select>
                        
                    </li>
                    <li>
                        <SearchForm />
                    </li>
                </ul>
         </div>
        </nav>
    )
    case "english":
        return (
            <nav className='buscador' id = "buscador">
                <div className="buscador-subtitle">
                    <span>Books Search</span>
                </div>
                <div className="buscador-collapse">
                    <ul className="buscador-nav">
                        <li className="us-form center">
                            Search by:
                                <select
                                    name="criterio"
                                    type="criterio"
                                    placeholder={criterio === "bookname" ? "Book's name" : "Author name"}
                                    value={criterio}
                                    onChange={(e) => setCriterio( e.target.value )}
                                    required
                                >
                                    <option value="bookname">Book's name</option>
                                    <option value="authorname">Author name</option>
                                </select>
                            
                        </li>
                        <li>
                            <SearchForm />
                        </li>
                    </ul>
             </div>
            </nav>
        )
        default:
        break;
}
}

export default Buscador;