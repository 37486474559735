import Dropdown from "./Dropdown";
import { useState, useEffect, useContext, useRef } from "react";
import { BookContext } from "../../../context/books";
import { useNavigate } from 'react-router-dom';
//import { Link } from "react-router-dom";

const MenuItems = ({ items, depthLevel }) => {
  const { fetchLibros, fetchGuias, fetchOtros, fetchRevistas } = useContext(BookContext);
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();
  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

async function handleEst (sOpt)  {
  if(sOpt) {
    switch (sOpt) {
      case "estmex": 
      await fetchLibros("México");
      navigate("book");
      break;
        case "mx4049": 
          await fetchLibros("México", "1940", "1949");
          navigate("book");
        break;
        case "mx5059": 
          await fetchLibros("México", "1950", "1959");
          navigate("book");
        break;
        case "mx6069": 
          await fetchLibros("México", "1960", "1969");
          navigate("book");
        break;
        case "mx7079": 
          await fetchLibros("México", "1970", "1979");
          navigate("book");
        break;
        case "mx8089": 
          await fetchLibros("México", "1980", "1989");
          navigate("book");
        break;
        case "mx9099": 
          await fetchLibros("México", "1990", "1999");
          navigate("book");
        break;
        case "mx0009": 
          await fetchLibros("México", "2000", "2009");
          navigate("book");
        break;
        case "mx1019": 
          await fetchLibros("México", "2010", "2019");
          navigate("book");
        break;
        case "mx2029": 
          await fetchLibros("México", "2020", "2029");
          navigate("book");
        break;
          case "guiasmx": 
            await fetchGuias("México", "all");
            navigate("guia");
          break;
            case "gmamer": 
              await fetchGuias("México", "Club América");
              navigate("guia");
            break;
            case "gmcrz": 
              await fetchGuias("México", "Cruz Azul");
              navigate("guia");
            break;
            case "gmchv": 
              await fetchGuias("México", "Chivas");
              navigate("guia");
            break;
            case "gmjag": 
              await fetchGuias("México", "Jaguares");
              navigate("guia");
            break;
            case "gmmty": 
              await fetchGuias("México", "Monterrey");
              navigate("guia");
            break;
            case "gmmor": 
              await fetchGuias("México", "Morelia");
              navigate("guia");
            break;
            case "gmpach": 
              await fetchGuias("México", "Pachuca");
              navigate("guia");
            break;
            case "gmpum": 
              await fetchGuias("México", "Pumas");
              navigate("guia");
            break;
            case "gmpsan": 
              await fetchGuias("México", "Santos Laguna");
              navigate("guia");
            break;
            case "gmtec": 
              await fetchGuias("México", "Tecos");
              navigate("guia");
            break;
            case "gmtol": 
              await fetchGuias("México", "Toluca");
              navigate("guia");
            break;
            case "gmfmf": 
              await fetchGuias("México", "FMF");
              navigate("guia");
            break;
        case "mxtamb": 
          navigate("mxytamb"); // Caso especial PAGINA
        break;
      case "estarg": 
      await fetchLibros("Argentina");
      navigate("book");
      break;
        case "ar4049": 
          await fetchLibros("Argentina", "1940", "1949");
          navigate("book");
        break;
        case "ar5059": 
          await fetchLibros("Argentina", "1950", "1959");
          navigate("book");
        break;
        case "ar6069": 
          await fetchLibros("Argentina", "1960", "1969");
          navigate("book");
        break;
        case "ar7079": 
          await fetchLibros("Argentina", "1970", "1979");
          navigate("book");
        break;
        case "ar8089": 
          await fetchLibros("Argentina", "1980", "1989");
          navigate("book");
        break;
        case "ar9099": 
          await fetchLibros("Argentina", "1990", "1999");
          navigate("book");
        break;
        case "ar0009": 
          await fetchLibros("Argentina", "2000", "2009");
          navigate("book");
        break;
        case "ar1019": 
          await fetchLibros("Argentina", "2010", "2019");
          navigate("book");
        break;
        case "ar2029": 
          await fetchLibros("Argentina", "2020", "2029");
          navigate("book");
        break;
          case "guiasar": 
            await fetchGuias("Argentina", "all");
            navigate("guia");
          break;
            case "gmcol": 
              await fetchGuias("Argentina", "Colón");
              navigate("guia");
            break;
            case "gmriv": 
              await fetchGuias("Argentina", "River Plate");
              navigate("guia");
            break;
            case "gmvel": 
              await fetchGuias("Argentina", "Velez Sarsfield");
              navigate("guia");
            break;
            case "gmarotr": 
              await fetchGuias("Argentina", "guiasArgOtras"); // Guias Caso especial
              navigate("guia");
            break;
        case "estesp": 
        await fetchLibros("España");
        navigate("book");
        break;
          case "es3039": 
          await fetchLibros("España", "1930", "1939");
          navigate("book");
        break;
        case "es4049": 
          await fetchLibros("España", "1940", "1949");
          navigate("book");
        break;
        case "es5059": 
          await fetchLibros("España", "1950", "1959");
          navigate("book");
        break;
        case "es6069": 
          await fetchLibros("España", "1960", "1969");
          navigate("book");
        break;
        case "es7079": 
          await fetchLibros("España", "1970", "1979");
          navigate("book");
        break;
        case "es8089": 
          await fetchLibros("España", "1980", "1989");
          navigate("book");
        break;
        case "es9099": 
          await fetchLibros("España", "1990", "1999");
          navigate("book");
        break;
        case "es0009": 
          await fetchLibros("España", "2000", "2009");
          navigate("book");
        break;
        case "es1019": 
          await fetchLibros("España", "2010", "2019");
          navigate("book");
        break;
        case "es2029": 
          await fetchLibros("España", "2020", "2029");
          navigate("book");
        break;        
        case "chile": 
          await fetchLibros("Chile");
          navigate("book");
        break;  
        case "colombia": 
          await fetchLibros("Colombia");
          navigate("book");
        break;  
        case "ecuador": 
          await fetchLibros("Ecuador");
          navigate("book");
        break;  
        case "francia": 
          await fetchLibros("Francia");
          navigate("book");
        break;  
        case "inglaterra": 
          await fetchLibros("Inglaterra");
          navigate("book");
        break;  
        case "alemania": 
          await fetchLibros("Alemania");
          navigate("book");
        break;  
        case "italia": 
          await fetchLibros("Italia");
          navigate("book");
        break;  
        case "belgica": 
          await fetchLibros("Bélgica");
          navigate("book");
        break;  
        case "brasil": 
          await fetchLibros("Brasil");
          navigate("book");
        break;  
        case "peru": 
          await fetchLibros("Perú");
          navigate("book");
        break;  
        case "fifa": 
          await fetchLibros("FIFA");
          navigate("book");
        break;
        case "concacaf": 
        await fetchGuias("Internacionales", "CONCACAF"); // Guia int
        navigate("guia");
        break; 
        case "conmebol": 
        await fetchLibros("Conmebol"); //Editorial
        navigate("book");
        break;
        case "iffhs": 
        await fetchLibros("IFFHS"); //Editorial
        navigate("book");
        break;
        case "confeder": 
        await fetchGuias("Internacionales", "Confederaciones"); // Guia int
        navigate("guia");
        break;
          case "masguias": 
          await fetchGuias("Internacionales", "masguias"); // Guia int todas
          navigate("guia");
          break;
            case "gmundiales": 
            await fetchGuias("Internacionales","FIFA Mayor"); // Guia int
            navigate("guia");
            break;
            case "gmundjuv": 
            await fetchGuias("Internacionales", "FIFA Juvenil"); // Guia int
            navigate("guia");
            break;
            case "gusa": 
            await fetchGuias("Internacionales", "USA"); // Guia int
            navigate("guia");
            break;
            case "gcopamer": 
            await fetchGuias("Internacionales", "Copa América"); // Guia int
            navigate("guia");
            break;
            case "gotras": 
            await fetchGuias("Internacionales", "Otras"); // Guias exclusion caso especial 
            navigate("guia");
            break;
        case "jjoo": 
        await fetchLibros("JJOO"); //Editorial
        navigate("book");
        break; 
        case "estotros": 
        await fetchLibros("otros"); //Editorial
        navigate("book");
        break; 
      case "automov": 
      await fetchOtros("automov"); // Automov todos
      navigate("otros");
      break;
        case "f1": 
        await fetchOtros("f1"); // Automov f1
        navigate("otros");
        break;
        case "scart":
        await fetchOtros("scart"); // Automov Serie Cart
        navigate("otros");
        break;  
      case "masdep": 
      await fetchLibros("masdep"); // Mas Deportes
      navigate("book");
      break;
      case "rock": 
      await fetchOtros("rock"); // Rock
      navigate("otros");
      break;
      case "descargas":
      navigate("descargas");
      break;
      case "revistas":
      await fetchRevistas("all");
      navigate("revista");
      break;
      default: break; 
        }
    }
}



  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const toggleDropdown = () => {
    setDropdown((prev) => !prev);
    handleEst(items.url);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}>
      {items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => toggleDropdown()}>
            {items.title}
            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}>
            {items.title}
            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <button
        type="button"
        onClick={(e) => handleEst(items.url)}>
        {items.title}
      </button>
      )
      }
    </li>
  );
};

export default MenuItems;