import React, { useContext } from "react";
import { BookContext } from "../../context/books";
import Otros from '../OtrosList/Otros';
import Loading from "../Loader/Loader";
import "./OtrosList.css";


const OtrosList = () => {
    const {otros, loading, resultTitle } = useContext(BookContext);

    if(loading) return <Loading />;

    return (
        <section className='otroslist'>
            <div className="container">
                <h2 className="result">{resultTitle}</h2>
            </div>
            <div className="otroslist-content grid">
                {otros.slice(0, 98).map((item, index) => {
                    return (
                        <Otros key = {index} {... item} />
                    )
                })}
            </div>
        </section>
    )
}

export default OtrosList;