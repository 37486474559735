import React, { useContext, useEffect, useRef, useState } from "react";
import { BookContext } from '../../../context/books';
import { menuItemsDataEsp } from "../menuItemsDataEsp";
import { menuItemsDataEng } from "../menuItemsDataEng";
import MobileMenuItems from "./MobileMenuItems";

const MobileNav = () => {
  const depthLevel = 0;
  const [showMenu, setShowMenu] = useState(false);
  const { idioma } = useContext(BookContext);
  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showMenu && ref.current && !ref.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showMenu]);

  switch (idioma) {
  case "español":
   return (
    <nav className="mobile-nav">
      <button
        className="mobile-nav__menu-button"
        type="button"
        onClick={() => setShowMenu((prev) => !prev)}>
        Estantes 
        <span className="arrow"></span>
      </button>

      {showMenu && (
        <ul className="menus" ref={ref}>
          {menuItemsDataEsp.map((menu, index) => {
            return (
              <MobileMenuItems
                items={menu}
                key={index}
                depthLevel={depthLevel}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
              />
            );
          })}
        </ul>
      )}
    </nav>
  );
  case "english":
    return (
      <nav className="mobile-nav">
        <button
          className="mobile-nav__menu-button"
          type="button"
          onClick={() => setShowMenu((prev) => !prev)}>
          Estantes 
          <span className="arrow"></span>
        </button>
  
        {showMenu && (
          <ul className="menus" ref={ref}>
            {menuItemsDataEng.map((menu, index) => {
              return (
                <MobileMenuItems
                  items={menu}
                  key={index}
                  depthLevel={depthLevel}
                  showMenu={showMenu}
                  setShowMenu={setShowMenu}
                />
              );
            })}
          </ul>
        )}
      </nav>
    );
   default:
      break;
  }
};

export default MobileNav;