import React, {useState, useContext } from "react";
import { Link } from 'react-router-dom';
import './Navbar.css';
import logoImg from '../../images/logo_cl.png';
import logoTitulo from '../../images/titulo.gif';
import logoTitle from '../../images/title.gif';
import {HiOutlineMenuAlt3} from 'react-icons/hi';
import { BookContext } from '../../context/books';


const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const handleNavbar = () => setToggleMenu(!toggleMenu);
    const { idioma, setIdioma } = useContext(BookContext);
switch (idioma) {
    case "español":
        return (
            <nav className='navbar' id = "navbar">
            <div className='container-navbar navbar-content flex'>
                <div className='brand-and-toggler flex flex-sb'>
                <Link to = "/" className='navbar-brand flex'>
                    <img src = {logoImg} alt = "site logo" />
                    <img src = {logoTitulo} alt = "site logo" />
                </Link>
                <button type = "button" className='navbar-toggler-btn' onClick={handleNavbar}>
                    <HiOutlineMenuAlt3 size = {35} style = {{ color: `${toggleMenu ? "green" : "#ddfcb6"}`}} />
                </button>
                </div>
    
                <div className={toggleMenu ? "navbar-collapse show-navbar-collapse" : "navbar-collapse"}>
                <ul className="navbar-nav">
                       <li className="nav-item">
                          <select
                                    name="idioma"
                                    type="idioma"
                                    placeholder={idioma === "español" ? "Español" : "English"}
                                    value={idioma}
                                    onChange={(e) => setIdioma( e.target.value )}
                                    required
                                >
                                    <option value="español">Español</option>
                                    <option value="english">English</option>
                                </select>
                            </li>
                    <li className="nav-item">
                        <Link to = "Inicio" className='nav-link text-uppercase text-green fs-22 fw-6'>Inicio</Link>
                    </li>
                    <li className="nav-item">
                        <Link to = "Present" className='nav-link text-uppercase text-green fs-22 fw-6'>Presentaciones</Link>
                    </li>
                    <li className="nav-item">
                        <Link to = "Autor" className='nav-link text-uppercase text-green fs-22 fw-6'>El Autor</Link>
                    </li>
                    <li className="nav-item">
                        <Link to = "Links" className='nav-link text-uppercase text-green fs-22 fw-6'>Links útiles</Link>
                    </li>
                    <li className="nav-item">
                        <Link to = "Contact" className='nav-link text-uppercase text-green fs-22 fw-6'>Contacto</Link>
                    </li>
                </ul>
                </div>
            </div>
            <div className="nav-subtitle">
            <span>Bienvenidos al Cuartito donde encontrarán una interesante biblioteca deportiva</span>
            </div>
            </nav>
        );   
    case "english":
        return (
            <nav className='navbar' id = "navbar">
            <div className='container-navbar navbar-content flex'>
                <div className='brand-and-toggler flex flex-sb'>
                <Link to = "/" className='navbar-brand flex'>
                    <img src = {logoImg} alt = "site logo" />
                    <img src = {logoTitle} alt = "site logo" />
                </Link>
                <button type = "button" className='navbar-toggler-btn' onClick={handleNavbar}>
                    <HiOutlineMenuAlt3 size = {35} style = {{ color: `${toggleMenu ? "green" : "#ddfcb6"}`}} />
                </button>
                </div>
    
                <div className={toggleMenu ? "navbar-collapse show-navbar-collapse" : "navbar-collapse"}>
                <ul className="navbar-nav">
                       <li className="nav-item">
                          <select
                                    name="idioma"
                                    type="idioma"
                                    placeholder={idioma === "español" ? "Español" : "English"}
                                    value={idioma}
                                    onChange={(e) => setIdioma( e.target.value )}
                                    required
                                >
                                    <option value="español">Español</option>
                                    <option value="english">English</option>
                                </select>
                        </li>
                     <li className="nav-item">
                    <Link to = "Inicio" className='nav-link text-uppercase text-green fs-22 fw-6'>Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to = "Present" className='nav-link text-uppercase text-green fs-22 fw-6'>Presentations</Link>
                    </li>
                    <li className="nav-item">
                        <Link to = "Autor" className='nav-link text-uppercase text-green fs-22 fw-6'>The Author</Link>
                    </li>
                    <li className="nav-item">
                        <Link to = "Links" className='nav-link text-uppercase text-green fs-22 fw-6'>Helpful Links</Link>
                    </li>
                    <li className="nav-item">
                        <Link to = "Contact" className='nav-link text-uppercase text-green fs-22 fw-6'>Contact</Link>
                    </li>
                 </ul>
                </div>
            </div>
            <div className="nav-subtitle">
            <span>Welcome to the Books Little Rooms, where you will find an interesting sport's library</span>
            </div>
            </nav>
        )
    default:
    break;
}

}

export default Navbar;