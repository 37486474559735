export const menuItemsDataEsp = [
    {
      title: "Estantes de México",
      url: "estmex",
      submenu: [
        {
          title: "1940-1949",
          url: "mx4049",
          tipo: "libros",
          pais: "México",
        },
        {
          title: "1950-1959",
          url: "mx5059",
          tipo: "libros",
          pais: "México",
        },
        {
          title: "1960-1969",
          url: "mx6069",
          tipo: "libros",
          pais: "México",
        },
        {
          title: "1970-1979",
          url: "mx7079",
          tipo: "libros",
          pais: "México",
        },
        {
          title: "1980-1989",
          url: "mx8089",
          tipo: "libros",
          pais: "México",
        },
        {
          title: "1990-1999",
          url: "mx9099",
          tipo: "libros",
          pais: "México",
        },
        {
          title: "2000-2009",
          url: "mx0009",
          tipo: "libros",
          pais: "México",
        },
        {
          title: "2010-2019",
          url: "mx1019",
          tipo: "libros",
          pais: "México",
        },          
        {
          title: "2020-2029",
          url: "mx2029",
          tipo: "libros",
          pais: "México",
        },                                                        
        {
          title: "Guías de medios",
          url: "guiasmx",
          submenu: [
            {
              title: "América",
              url: "gmamer",
              tipo: "guias",
              pais: "México",
            },
            {
              title: "Cruz Azul",
              url: "gmcrz",
              tipo: "guias",
              pais: "México",
            },
            {
              title: "Chivas",
              url: "gmchv",
              tipo: "guias",
              pais: "México",
            },
            {
              title: "Jaguares",
              url: "gmjag",
              tipo: "guias",
              pais: "México",
            },
            {
              title: "Monterrey",
              url: "gmmty",
              tipo: "guias",
              pais: "México",
            },
            {
              title: "Morelia",
              url: "gmmor",
              tipo: "guias",
              pais: "México",
            },
            {
              title: "Pachuca",
              url: "gmpach",
              tipo: "guias",
              pais: "México",
            },
            {
              title: "Pumas",
              url: "gmpum",
              tipo: "guias",
              pais: "México",
            },
            {
              title: "Santos Laguna",
              url: "gmsan",
              tipo: "guias",
              pais: "México",
            },
            {
              title: "Tecos",
              url: "gmtec",
              tipo: "guias",
              pais: "México",
            },                                    
            {
              title: "Toluca",
              url: "gmtol",
              tipo: "guias",
              pais: "México",
            },          
            {
              title: "FMF",
              url: "gmfmf",
              tipo: "guias",
              pais: "México",
            },  
          ],
        },
        {
          title: "Y tambien existen...",
          url: "mxtamb",
          tipo: "libros",
          pais: "México",
        }
      ],
    },
    {
      title: "Estantes de Argentina",
      url: "estarg",
      submenu: [
        {
          title: "1940-1949",
          url: "ar4049",
          tipo: "libros",
          pais: "Argentina",
        },
        {
          title: "1950-1959",
          url: "ar5059",
          tipo: "libros",
          pais: "Argentina",
        },
        {
          title: "1960-1969",
          url: "ar6069",
          tipo: "libros",
          pais: "Argentina",
        },
        {
          title: "1970-1979",
          url: "ar7079",
          tipo: "libros",
          pais: "Argentina",
        },
        {
          title: "1980-1989",
          url: "ar8089",
          tipo: "libros",
          pais: "Argentina",
        },
        {
          title: "1990-1999",
          url: "ar9099",
          tipo: "libros",
          pais: "Argentina",
        },
        {
          title: "2000-2009",
          url: "ar0009",
          tipo: "libros",
          pais: "Argentina",
        },
        {
          title: "2010-2019",
          url: "ar1019",
          tipo: "libros",
          pais: "Argentina",
        },          
        {
          title: "2020-2029",
          url: "ar2029",
          tipo: "libros",
          pais: "Argentina",
        },                                                        
        {
          title: "Guías de medios",
          url: "guiasar",
          submenu: [
            {
              title: "Colón",
              url: "gmcol",
              tipo: "guias",
              pais: "Argentina",
            },
            {
              title: "River Plate",
              url: "gmriv",
              tipo: "guias",
              pais: "Argentina",
            },
            {
              title: "Velez Sarsfield",
              url: "gmvel",
              tipo: "guias",
              pais: "Argentina",
            },
            {
              title: "Otras",
              url: "gmarotr",
              tipo: "guias",
              pais: "Argentina",
            },
          ],
        },
      ],
    },
    {
      title: "Estantes de España",
      url: "estesp",
      submenu: [
        {
          title: "1930-1939",
          url: "es3039",
          tipo: "libros",
          pais: "España",
        },
        {
          title: "1940-1949",
          url: "es4049",
          tipo: "libros",
          pais: "España",
        },
        {
          title: "1950-1959",
          url: "es5059",
          tipo: "libros",
          pais: "España",
        },
        {
          title: "1960-1969",
          url: "es6069",
          tipo: "libros",
          pais: "España",
        },
        {
          title: "1970-1979",
          url: "es7079",
          tipo: "libros",
          pais: "España",
        },
        {
          title: "1980-1989",
          url: "es8089",
          tipo: "libros",
          pais: "España",
        },
        {
          title: "1990-1999",
          url: "es9099",
          tipo: "libros",
          pais: "España",
        },
        {
          title: "2000-2009",
          url: "es0009",
          tipo: "libros",
          pais: "España",
        },
        {
          title: "2010-2019",
          url: "es1019",
          tipo: "libros",
          pais: "España",
        },          
        {
          title: "2020-2029",
          url: "es2029",
          tipo: "libros",
          pais: "España",
        },                                                        
      ],
    },
    {
      title: "Más Estantes",
      url: "",
      submenu: [
        {
          title: "Chile",
          url: "chile",
          tipo: "libros",
          pais: "Chile",
        },
        {
          title: "Colombia",
          url: "colombia",
          tipo: "libros",
          pais: "Colombia",
        },
        {
          title: "Ecuador",
          url: "ecuador",
          tipo: "libros",
          pais: "Ecuador",
        },
        {
          title: "Francia",
          url: "francia",
          tipo: "libros",
          pais: "Francia",
        },
        {
          title: "Inglaterra",
          url: "inglaterra",
          tipo: "libros",
          pais: "Inglaterra",
        },
        {
          title: "Alemania",
          url: "alemania",
          tipo: "libros",
          pais: "Alemania",
        },
        {
          title: "Italia",
          url: "italia",
          tipo: "libros",
          pais: "Italia",
        },
        {
          title: "Bélgica",
          url: "belgica",
          tipo: "libros",
          pais: "Bélgica",
        },
        {
          title: "Brasil",
          url: "brasil",
          tipo: "libros",
          pais: "Brasil",
        },          
        {
          title: "Perú",
          url: "peru",
          tipo: "libros",
          pais: "Perú",
        },
        {
          title: "FIFA",
          url: "fifa",
          tipo: "libros",
          pais: "FIFA",
     },
        {
          title: "Concacaf",
          url: "concacaf",
          tipo: "guias",
          pais: "Concacaf",
        },
        {
          title: "Conmebol",
          url: "conmebol",
          tipo: "libros",
          pais: "Conmebol",
        },
        {
          title: "IFFHS",
          url: "iffhs",
          tipo: "libros",
          pais: "IFFHS",
        },
        {
          title: "Copa Confederaciones",
          url: "confeder",
          tipo: "guias",
          pais: "Confederaciones",
        },
        {
          title: "Más Guías de Medios",
          url: "masguias",
          submenu: [
            {
              title: "Mundiales",
              url: "gmundiales",
              tipo: "guias",
              pais: "FIFA Mayor",
                },
            {
              title: "Mundiales Juveniles",
              url: "gmundjuv",
              tipo: "guias",
              pais: "FIFA Juvenil",            },
            {
              title: "Guías de EEUU",
              url: "gusa",
              tipo: "guias",
              pais: "USA",           },
            {
              title: "Copa América",
              url: "gcopamer",
              tipo: "guias",
              pais: "Copa América",            },
            {
              title: "Otras",
              url: "gotras",
              tipo: "guias",
              pais: "Otras",            },
          ]
        },
        {
          title: "Juegos Olímpicos",
          url: "jjoo",
          tipo: "libros",
          pais: "JJOO",        },
        {
          title: "Otros estantes...",
          url: "estotros",
          tipo: "libros",
          pais: "otros",        },
      ],
    },
    {
      title: "Automovilismo",
      url: "automov",
      submenu: [
        {
          title: "F1",
          url: "f1",
          tipo: "otros",
          pais: "F1",        },
        {
          title: "Serie CART",
          url: "scart",
          tipo: "automov",
          pais: "CART",        },
      ],
    },
    {
      title: "Más deportes",
      url: "masdep",
      tipo: "masdep",
      pais: "masdep",    },
    {
      title: "Rock",
      url: "rock",
      tipo: "rock",
      pais: "rock",    },
    {
      title: "Descargas",
      url: "descargas",
/*      submenu: [
        {
          title: "De enlaces",
          url: "links",
        },
        {
          title: "Libros en PDF",
          url: "pdfbooks",
        },
      ],*/
    },
    {
    title: "Revistas",
    url: "revistas",
    revista: "all",
/*      submenu: [
        {
          title: "Español",
          url: "esp",
        },
        {
          title: "Ingles",
          url: "eng",
        },
      ]*/
    }
  ];