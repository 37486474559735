import React, { useContext } from 'react';
import { generateClient } from "aws-amplify/api";
import { useNavigate } from 'react-router-dom';
import { BookContext } from '../../context/books';
import { CarruselContext } from '../../context/carruselContext';
import { listLibros, listMasdeps, listAutomovs } from "../../api/queries";
import { getCurrentUser } from "aws-amplify/auth";
import Loading from "../../components/Loader/Loader";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './Carrusel.css';

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import config from '../../aws-exports';
const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket
} = config


const client = generateClient({});


export default function Carrusel() {

  const navigate = useNavigate();
  const { idioma } = useContext(BookContext);
  const { carrusel, loading } = useContext(CarruselContext);
  var destacText = "";

  async function authenticated() {
    try {
        let { username } = await getCurrentUser();
          if (username) return true;
    } catch {
        return false;
    }
  }

  switch (idioma) {
  case "español":
    destacText = "DESTACADOS DEL MES";
    break;
  case "english":
    destacText = "MONTHLY HIGHLIGHTS";
    break;
  default:
    break;
}

  //useEffect(() => {
  //  fetchCarrusel();
  //}, [fetchCarrusel])

  async function handleItem(foto) {
    let filtro = { portada: { eq: foto } };
      try {
        let isLoggedIn = await authenticated();
        const { data } = await client.graphql({
          query: listLibros,
          variables: {
            limit: 1000,
            filter: filtro
          },
          authMode: isLoggedIn ? "userPool" : "iam"
        });
        if (data.listLibros.items.length !== 0) {
          let idD = data.listLibros.items[0].id;
          navigate (`book/id/${idD}`);
                } else { 
                  try {
                    let isLoggedIn = await authenticated();
                    const { data } = await client.graphql({
                      query: listMasdeps,
                      variables: {
                        filter: filtro
                      },
                      authMode: isLoggedIn ? "userPool" : "iam"
                    });
                    if (data.listMasdeps.items.length !== 0) {
                      let idD = data.listMasdeps.items[0].id;
                      navigate (`book/id/${idD}`);
                      }  else {
                        try {
                          let isLoggedIn = await authenticated();
                          const { data } = await client.graphql({
                            query: listAutomovs,
                            variables: {
                              filter: filtro
                            },
                            authMode: isLoggedIn ? "userPool" : "iam"
                           });
                          if (data.listAutomovs.items.length !== 0) {
                            let idD = data.listAutomovs.items[0].id
                            navigate (`otros/id/${idD}`);
                          }  return;      
                        } catch (err) {
                          console.log(err);
                        };
                     } 
                } catch (err) {
                  console.log(err);
                };
             } 
      } catch (err) {
        console.log(err);
      }
  };

  if(loading) return <Loading />;

  return (
    <>
    <div className="about-text">
      <h2 className='about-title fs-26 ls-1'>{destacText}</h2>
    </div>
      <Swiper
        effect={'coverflow'}
        grabCursor={false}
        centeredSlides={true}
        slidesPerView={'auto'}
        slidesPerGroup={1}
        autoplay={{delay: 2000, disableOnInteraction: false}}
        loop={true}
        coverflowEffect={{
          delay: 1000,
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={false}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper"
      >
        {carrusel.map((item, index) => {
          return (
            <SwiperSlide key = {index}>
            <button onClick={() => { handleItem(item.nomfoto)}}>
            <img src={`https://${bucket}.s3.${region}.amazonaws.com/images/${item.nomfoto}`} alt={item.titulo} />
            </button>
          </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  );
}
/*
        <SwiperSlide>
          <button onClick={() => { handleItem(carrItemsData[0].nomfoto)}}>
          <img src={carrItemsData[0].imgurl} alt={carrItemsData[0].titulo} />
          </button>
        </SwiperSlide>
        <SwiperSlide>
        <button onClick={() => { handleItem(carrItemsData[1].nomfoto)}}>
          <img src={carrItemsData[1].imgurl} alt={carrItemsData[1].titulo} />
        </button>
        </SwiperSlide>
        <SwiperSlide>
        <button onClick={() => { handleItem(carrItemsData[2].nomfoto)}}>
          <img src={carrItemsData[2].imgurl} alt={carrItemsData[2].titulo} />
        </button>
        </SwiperSlide>
        <SwiperSlide>
        <button onClick={() => { handleItem(carrItemsData[3].nomfoto)}}>
          <img src={carrItemsData[3].imgurl} alt={carrItemsData[3].titulo} />
        </button>
        </SwiperSlide>
        <SwiperSlide>
        <button onClick={() => { handleItem(carrItemsData[4].nomfoto)}}>
          <img src={carrItemsData[4].imgurl} alt={carrItemsData[4].titulo} />
        </button>
        </SwiperSlide>
        <SwiperSlide>
        <button onClick={() => { handleItem(carrItemsData[5].nomfoto)}}>
          <img src={carrItemsData[5].imgurl} alt={carrItemsData[5].titulo} />
        </button>
        </SwiperSlide>

*/