import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./GuiaList.css";
import coverImg from "../../images/cover_not_found.jpg";
import config from '../../aws-exports';
import { BookContext } from '../../context/books';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const Guia = (guia) => {
    const { idioma } = useContext(BookContext);
    switch (idioma) {
      case "español":
    return (
        <div className="guia-item flex flex-column flex-sb">
          <Link to = {`/guia/id/${guia.id}`} {...guia}>
            <div className="guia-item-img">
                <img src = {guia.nomfoto ? `https://${bucket}.s3.${region}.amazonaws.com/images/${guia.nomfoto}` : coverImg} alt = "cover" />
            </div>
          </Link>
            <div className="guia-item-info text-center">
                <Link to = {`/guia/id/${guia.id}`} {...guia}>
                    <div className="guia-inte-info-item title fw-7 fs-18">
                        <span>{guia.titulo}</span>
                    </div>
                </Link>

                <div className="guia-inte-info-item author fs-15">
                    <span className="text-capitalized fw-7">Institución: </span>
                    <span>{guia.institucion}</span>
                </div>
               <Link to = {`/guia/id/${guia.id}`} {...guia}>
                <div className="guia-inte-info-item author fw-3 fs-09">
                <span className="fw-3 fs-09">(Click para mas datos)</span>
                </div>
                </Link>
            </div>
        </div>
    )
    case "english":
        return (
            <div className="guia-item flex flex-column flex-sb">
              <Link to = {`/guia/id/${guia.id}`} {...guia}>
                <div className="guia-item-img">
                    <img src = {guia.nomfoto ? `https://${bucket}.s3.${region}.amazonaws.com/images/${guia.nomfoto}` : coverImg} alt = "cover" />
                </div>
              </Link>
                <div className="guia-item-info text-center">
                    <Link to = {`/guia/id/${guia.id}`} {...guia}>
                        <div className="guia-inte-info-item title fw-7 fs-18">
                            <span>{guia.titulo}</span>
                        </div>
                    </Link>
    
                    <div className="guia-inte-info-item author fs-15">
                        <span className="text-capitalized fw-7">Institution: </span>
                        <span>{guia.institucion}</span>
                    </div>
                   <Link to = {`/guia/id/${guia.id}`} {...guia}>
                    <div className="guia-inte-info-item author fw-3 fs-09">
                    <span className="fw-3 fs-09">(Click for more info)</span>
                    </div>
                    </Link>
                </div>
            </div>
        )
        default:
            break;
}
}

export default Guia;