import React, { useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { BookContext } from "../../context/books";
import './ExtrasMenu.css';


const ExtrasMenu = () => {
    const navigate = useNavigate();
    const { fetchOtros, idioma } = useContext(BookContext);

    async function fetchExtras (opt) {
    await fetchOtros (opt);
    navigate ("otros");
    };
    switch (idioma) {
        case "español":
    return (
        <nav className='extrasmenu' id = "extrasmenu">
            <div className="extrasmenu-subtitle">
                <span>Extras</span>
            </div>
            <div className="extrasmenu-collapse">
                <ul className="extrasmenu-nav">
                    <li className="extrasmenu-item">
                        <Link to = "Agradec" className='extrasmenu-link fs-16 fw-6'>Agradecimientos</Link>
                    </li>
                    <li className="extrasmenu-item">
                        <Link to = "Razones" className='extrasmenu-link fs-16 fw-6'>Razones por las que existe el cuartito</Link>
                    </li>
                    <li className="extrasmenu-item">
                    <button className='extrasmenu-link fs-16 fw-6' onClick={() => fetchExtras("tambien")}>En un cuartito tambien estan..</button>
                    </li>
                    <li className="extrasmenu-item">
                        <Link to = "Inicio" className='extrasmenu-link fs-16 fw-6'>Compra - Venta de material</Link>
                    </li>
                </ul>
         </div>
        </nav>
    )
    case "english":
        return (
            <nav className='extrasmenu' id = "extrasmenu">
                <div className="extrasmenu-subtitle">
                    <span>Extras</span>
                </div>
                <div className="extrasmenu-collapse">
                    <ul className="extrasmenu-nav">
                        <li className="extrasmenu-item">
                            <Link to = "Agradec" className='extrasmenu-link fs-16 fw-6'>Thanks to</Link>
                        </li>
                        <li className="extrasmenu-item">
                            <Link to = "Razones" className='extrasmenu-link fs-16 fw-6'>Reasons why the little's room exists</Link>
                        </li>
                        <li className="extrasmenu-item">
                        <button className='extrasmenu-link fs-16 fw-6' onClick={() => fetchExtras("tambien")}>In the little's romm there is also...</button>
                        </li>
                        <li className="extrasmenu-item">
                            <Link to = "Inicio" className='extrasmenu-link fs-16 fw-6'>Buy - Sell Stuff</Link>
                        </li>
                    </ul>
             </div>
            </nav>
        )
    default:
            break;
    }
}

export default ExtrasMenu;