import React, { useEffect, useCallback, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { getCurrentUser } from "aws-amplify/auth";
import { listCarruselItems } from "../api/queries";

const CarruselContext = React.createContext();

const client = generateClient({});

const CarrProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [carrusel, setCarrusel] = useState([]);

    async function authenticated() {
        try {
            let { username } = await getCurrentUser();
              if (username) return true;
        } catch {
            return false;
        }
      }

      const fetchCarrusel = useCallback(async() => {
        setLoading(true);
       try {
        let isLoggedIn = await authenticated();
         const { data } = await client.graphql({
           query: listCarruselItems,
           authMode: isLoggedIn ? "userPool" : "iam"
         });
         if (data) {
         const boolks = data.listCarruselItems.items;
         boolks.sort((a,b) => a.indice - b.indice);
         setCarrusel(boolks);
         setLoading(false);
       } else { 
         setCarrusel([]); 
       }
       } catch (err) {
         console.log(err);
         setLoading(false);
       }
      }, [])
    
    
    
    
       useEffect(() => {
        fetchCarrusel();
      }, [fetchCarrusel]);
    
      return (
        <CarruselContext.Provider value={{ carrusel, loading, fetchCarrusel }}>
          {children}
        </CarruselContext.Provider>
      );
    };
    
    export { CarruselContext, CarrProvider };