import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { BookContext } from '../../context/books';
import logoAmigos1 from '../../images/pulso/pulso_anim.gif';
import './Amigos.css';


const Amigos = () => {
    const { idioma } = useContext(BookContext);
    switch (idioma) {
      case "español":
    return (
        <nav className='amigos' id = "amigos">
            <div className="amigos-subtitle">
                <span>Amigos</span>
            </div>
            <div className="amigos-collapse">
            <Link to = "/" className='amigos-item'>
                <img src = {logoAmigos1} alt = "site logo" />
            </Link>
         </div>
        </nav>
    )
    case "english":
        return (
            <nav className='amigos' id = "amigos">
                <div className="amigos-subtitle">
                    <span>Friends</span>
                </div>
                <div className="amigos-collapse">
                <Link to = "/" className='amigos-item'>
                    <img src = {logoAmigos1} alt = "site logo" />
                </Link>
             </div>
            </nav>
        )
        default:
            break;
    }
}

export default Amigos;