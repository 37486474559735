import React, {useRef, useEffect, useContext } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BookContext } from "../../context/books";
import "./SearchForm.css";

const SearchForm = () => {
    const {setSearchTerm, setResultTitle } = useContext(BookContext);
    const searchText = useRef('');
    const navigate = useNavigate();

    useEffect(() => searchText.current.focus(), []);
    const handleSubmit = (e) => {
        e.preventDefault();
        let tempSearchTerm = searchText.current.value.trim();
        if((tempSearchTerm.replace(/[^\w\s]/gi,"")).length === 0){
            setSearchTerm("El librooo");
            setResultTitle("Agregue algo...");
        } else {
            setSearchTerm(searchText.current.value.toLowerCase().replace("á", "a").replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u"));
        }
        navigate("book");
    }

    return (
        <div className='search-form'>
            <div className='containersf'>
                <div className='search-form-content'>
                    <form className='search-form' onSubmit={handleSubmit}>
                        <div className='search-form-elem flex flex-sb bg-white'>
                            <input type = "text" className='form-control' placeholder='Escriba aqui el texto a buscar...' ref = {searchText} />
                            <button type = "submit" className='flex flex-c' onClick={handleSubmit}>
                                <FaSearch className='text-green' size = {16} />
                            </button>
                        </div> 
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SearchForm;