/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://dq5avat3yzhnrc4tjhh7yppl6u.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:75e8c9f3-d03c-4cda-8e33-79a1532eeff2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_aSGuBmdnd",
    "aws_user_pools_web_client_id": "6d044d5qee2q523ml2eh3srtkf",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_content_delivery_bucket": "cuartito-20240410215458-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d1afxa2b90g33j.cloudfront.net",
    "aws_user_files_s3_bucket": "cuartitoe42c39dd0cb740d59ea5c45a6bd926bb9a5af-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
