import React, { useEffect, useCallback, useState } from "react";
import { Link } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";
import { getCurrentUser } from "aws-amplify/auth";
import { listLibros, listEventos, getLibros, listGuiasargs, listGuiasints, listGuiasmxes, listAutomovs, listRocks, listMasdeps, listTambiens, listRevistas, getMasdep, getGuiasmx, getGuiasarg, getGuiasint, getAutomov, getRock, getTambien, getEventos, getVentas, getRevistas } from "../api/queries";

const BookContext = React.createContext();

const client = generateClient({});

const AppProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermRev, setSearchTermRev] = useState("");
  const [idioma, setIdioma] = useState("español");
  const [criterio, setCriterio] = useState("bookname");
  const [tablaEdit, setTablaEdit] = useState("");
  const [presents, setPresent] = useState([]);
  const [ventas, setVentas] = useState([]);
  const [books, setBooks] = useState([]);
  const [guias, setGuias] = useState([]);
  const [otros, setOtros] = useState([]);
  const [revistas, setRevistas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultTitle, setResultTitle] = useState("");

  async function authenticated() {
    try {
        let { username } = await getCurrentUser();
          if (username) return true;
    } catch {
        return false;
    }
  }

  const fetchBooks = useCallback(async() => {
    if (searchTerm === "" && searchTermRev === "") {
      return <Link to = "/inicio"></Link> 
    } else {
    setLoading(true);
    let filtroCrit;
    let query;
    let queryStr = "";
    if (searchTerm !== "") {
      filtroCrit = { tituloMin: { contains: searchTerm }};
    if (criterio === "authorname") {
      filtroCrit = { autorMin: { contains: searchTerm }};
    }
      query = listLibros;
      queryStr = "listLibros";
      } else {
      filtroCrit = { tituloMin: { contains: searchTermRev }};
    if (criterio === "authorname") {
      filtroCrit = { autorMin: { contains: searchTermRev }};
    }
      query = listRevistas;
      queryStr = "listRevistas";
    }
    try {
      let isLoggedIn = await authenticated();
      const { data } = await client.graphql({
        query: query,
        variables: {
          limit: 1000,
          filter: filtroCrit
        },
        authMode: isLoggedIn ? "userPool" : "iam"
      });
      if (data) {
      const boolks = data[queryStr].items;
      boolks.sort((a,b) => a.anoedicion - b.anoedicion);
      if (searchTerm !== "") {
        setBooks(boolks);
        setTablaEdit("Libros");
      } else {
        setRevistas(boolks);
        setTablaEdit("Revistas");
      }
      setLoading(false);
      setSearchTerm("");
      setSearchTermRev("");
      if(boolks.length >= 1){
          setResultTitle(`Resultados(${boolks.length}):`);
        } else {
          setResultTitle("No hay resultados!")
        };
    } else { 
      setBooks([]); 
      setSearchTerm("");
      setSearchTermRev("");
      setResultTitle("No hay resultados");
    }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }
  }, [searchTerm, searchTermRev, criterio ]);

  const fetchPresent = useCallback(async() => {
    setLoading(true);
    try {
      let isLoggedIn = await authenticated();
      const { data } = await client.graphql({
        query: listEventos,
        authMode: isLoggedIn ? "userPool" : "iam"
      });
      if (data) {
      const boolks = data.listEventos.items;
      boolks.sort((a,b) => a.anoevento - b.anoevento);
      setPresent(boolks);
      setLoading(false);
      setResultTitle(`Resultados(${boolks.length}):`);
    } else { 
      setPresent([]); 
      setResultTitle("No hay resultados");
    }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);


const fetchLibros = useCallback(async (pais, anInic, anFinal) => {
  setLoading(true);
  let queryFL = {};
  let queryFLstr = ""; 
  let filterFL = {};
  if (anInic && anFinal) {
    queryFL = listLibros;
    queryFLstr = "listLibros";
    filterFL = {pais: { eq: pais},
    anoedicion: { ge: anInic, le: anFinal }};
  } else {
  switch (pais) {
    case "IFFHS":
    case "Conmebol":
    case "FIFA":
      queryFL = listLibros;
      queryFLstr = "listLibros";
      filterFL = { or: [{editorial: { contains: pais}}, {pais: { eq: pais}}]};
    break;
    case "masdep":
      queryFL = listMasdeps;
      queryFLstr = "listMasdeps";
    break;
    case "otros":
      queryFL = listLibros;
      queryFLstr = "listLibros";
      filterFL = {not: {pais: { contains: "Argentina" }, 
                  not: {pais: { contains: "México" },
                  not: {pais: { contains: "España" },
                  not: {pais: { contains: "Chile" },
                  not: {pais: { contains: "Inglaterra" },
                  not: {pais: { contains: "Francia" },
                  not: {pais: { contains: "Alemania" },
                  not: {pais: { contains: "Italia" },
                  not: {pais: { contains: "Colombia" },
                  not: {pais: { contains: "JJOO" },
                  not: {pais: { contains: "Perú" },
                  not: {pais: { contains: "Brasil" },
                  not: {pais: { contains: "Bélgica" },
                  not: {pais: { contains: "Ecuador" },
                  not: {editorial: { contains: "FIFA" },
                  not: {editorial: { contains: "Conmebol" },
                  not: {pais: { contains: "Conmebol" },
                  not: {editorial: { contains: "IFFHS" },
                 }}}}}}}}}}}}}}}}}}};
    break;
    default: 
      queryFL = listLibros;
      queryFLstr = "listLibros";
      filterFL = {pais: { eq: pais}};
    break;
  }}
    try {
      let isLoggedIn = await authenticated();
      const { data } = await client.graphql({
        query: queryFL,
        variables: { limit: 1000, filter: filterFL },
        authMode: isLoggedIn ? "userPool" : "iam"
        });
      if (data) {
      const boolks = data[queryFLstr].items;
      boolks.sort((a,b) => a.anoedicion - b.anoedicion);
      setLoading(false);
      setBooks(boolks);
      setResultTitle(`Resultados(${boolks.length}):`);
    } else { 
      setBooks([]); 
      setResultTitle("No haber resultados");
    }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
}, [])

const fetchRevistas = useCallback(async (revista) => {
  setLoading(true);
  let queryFL = {};
  let queryFLstr = ""; 
  let filterFL = {};
  switch (revista) {
    case "all":
      queryFL = listRevistas;
      queryFLstr = "listRevistas";
    break;
    default: 
      queryFL = listRevistas;
      queryFLstr = "listRevistas";
      filterFL = {titulo: { eq: revista}};
    break;
  }
    try {
      let isLoggedIn = await authenticated();
      const { data } = await client.graphql({
        query: queryFL,
        variables: { limit: 1000, filter: filterFL },
        authMode: isLoggedIn ? "userPool" : "iam"
        });
      if (data) {
      const boolks = data[queryFLstr].items;
      boolks.sort((a,b) => a.anoedicion - b.anoedicion);
      setLoading(false);
      if(boolks.length >= 1){
        setResultTitle(`Resultados(${boolks.length}):`);
      } else {
        setResultTitle("No hay resultados!")
      };
      setRevistas(boolks);
    } else { 
      setRevistas([]); 
      setResultTitle("No haber resultados");
    }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
}, [])


const fetchGuias = useCallback(async (pais, institucion) => {
  setLoading(true);
  let queryFG = {};
  let queryFGstr = ""; 
  let filterFG = {};
  switch (pais) {
    case "México":
      if (institucion === "all") {  
        queryFG = listGuiasmxes;
        queryFGstr = "listGuiasmxes";
      } else {
        queryFG = listGuiasmxes;
        filterFG = { institucion: { eq: institucion }}
        queryFGstr = "listGuiasmxes";
      }
    break;
    case "Argentina":
      switch (institucion) {
        case "all":
          queryFG = listGuiasargs;
          queryFGstr = "listGuiasargs";
          break;
        case "guiasArgOtras":
          queryFG = listGuiasargs;
          filterFG = { not: {institucion: { eq: "Colón" }, 
                       not: {institucion: { eq: "River Plate" },
                       not: {institucion: { eq: "Velez Sarsfield" }
                      }}}}
          queryFGstr = "listGuiasargs";
        break;
        default:
          queryFG = listGuiasargs;
          filterFG = { institucion: { eq: institucion }}
          queryFGstr = "listGuiasargs";
        break;
      }
    break;
    case "Internacionales":
      switch (institucion) {
        case "masguias":
        queryFG = listGuiasints;
        filterFG = {};
        queryFGstr = "listGuiasints";
        break;
        case "Otras":
          queryFG = listGuiasints;
          queryFGstr = "listGuiasints";
          filterFG = {not: {institucion: { contains: "FIFA Mayor" }, 
                      not: {institucion: { eq: "FIFA Juvenil" },
                      not: {institucion: { contains: "USA" },
                      not: {institucion: { eq: "Copa América" },
                      not: {institucion: { eq: "Confederaciones" },
                      not: {institucion: { eq: "CONCACAF" },
                      }}}}}}};
        break;
        default:
          queryFG = listGuiasints;
          queryFGstr = "listGuiasints";
          filterFG = {institucion: { contains: institucion}}
        break;
      }
    break;
    default:
      break;
    }
    try {
      let isLoggedIn = await authenticated();
      const { data } = await client.graphql({
        query: queryFG,
        variables: {
          filter: filterFG
                  },
        authMode: isLoggedIn ? "userPool" : "iam"
        });
      if (data) {
      const boolks = data[queryFGstr].items;
      boolks.sort((a,b) => a.indice.localeCompare(b.indice));
      setLoading(false);
      setGuias(boolks);
      setResultTitle(`Resultados(${boolks.length}):`);
    } else { 
      setGuias([]); 
      setResultTitle("No haber resultados");
    }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
}, [])

const fetchOtros = useCallback(async (estante) => {
  let queryOtros = {};
  let filterAut = {};
  let queryOtr = "";
  setLoading(true);
  switch (estante) {
    case "automov":
      queryOtros = listAutomovs;
      queryOtr = "listAutomovs";
    break;
    case "f1":
      queryOtros = listAutomovs;
      filterAut = {categ: { eq: "f1" }};
      queryOtr = "listAutomovs";
    break;
    case "scart":
      queryOtros = listAutomovs;
      filterAut = {categ: { eq: "cart" }};
      queryOtr = "listAutomovs";
    break;
    case "rock":
      queryOtros = listRocks;
      queryOtr = "listRocks";
    break;
    case "tambien":
      queryOtros = listTambiens;
      queryOtr = "listTambiens";
    break;
    default:
    break;
  }
  try {
    let isLoggedIn = await authenticated();
    const { data } = await client.graphql({
      query: queryOtros,
      variables: { filter: filterAut },
      authMode: isLoggedIn ? "userPool" : "iam"
    });
    if (data) {
       const boolks = data[queryOtr].items;
       boolks.sort((a,b) => a.anoedicion - b.anoedicion);
       setLoading(false);
       setOtros(boolks);
       setResultTitle(`Resultados(${boolks.length}):`);
  } else { 
    setOtros([]); 
    setResultTitle("No haber resultados");
  }
  } catch (err) {
    console.log(err);
    setLoading(false);
  }
}, [])

const getBook = useCallback(async (idD) => {
  setLoading(true);
  try {
    let isLoggedIn = await authenticated();
    const { data } = await client.graphql({
      query: getLibros,
      variables: {
        id: idD },
        authMode: isLoggedIn ? "userPool" : "iam"
      }
    );
    if (data.getLibros) {
    const boolks = data.getLibros;
    setLoading(false);
    return boolks;
  } else { 
    try {
      let isLoggedIn = await authenticated();
      const { data } = await client.graphql({
        query: getMasdep,
        variables: {
          id: idD },
        authMode: isLoggedIn ? "userPool" : "iam"
        }
      );
      if (data) {
      const boolks = data.getMasdep;
      setLoading(false);
      return boolks;
      }  else {
        setBooks([]); 
        setResultTitle("No haber resultados");
      }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  } catch (err) {
    console.log(err);
    setLoading(false);
  }
}, []);


const getRevista = useCallback(async (idD) => {
  setLoading(true);
  try {
    let isLoggedIn = await authenticated();
    const { data } = await client.graphql({
      query: getRevistas,
      variables: {
        id: idD },
        authMode: isLoggedIn ? "userPool" : "iam"
      }
    );
      if (data) {
      const boolks = data.getRevistas;
      setLoading(false);
      return boolks;
      }  else {
        setRevistas([]); 
        setResultTitle("No haber resultados");
      }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
}, []);

const getGuia = useCallback(async (idD) => {
  setLoading(true);
  try {
    let isLoggedIn = await authenticated();
    const { data } = await client.graphql({
      query: getGuiasmx,
      variables: {
        id: idD },
      authMode: isLoggedIn ? "userPool" : "iam"
      }
    );
    if (data.getGuiasmx) {
    const boolks = data.getGuiasmx;
    setLoading(false);
    return boolks;
  } else {
        try {
          let isLoggedIn = await authenticated();
          const { data } = await client.graphql({
            query: getGuiasarg,
            variables: {
              id: idD },
            authMode: isLoggedIn ? "userPool" : "iam"              
            }
          );
            if (data.getGuiasarg) {
            const boolks = data.getGuiasarg;
            setLoading(false);
            return boolks;
                    } else {
                      try {
                        let isLoggedIn = await authenticated();
                        const { data } = await client.graphql({
                          query: getGuiasint,
                          variables: {
                            id: idD },
                          authMode: isLoggedIn ? "userPool" : "iam"                            
                          }
                        );
                        if (data) {
                        const boolks = data.getGuiasint;
                        setLoading(false);
                        return boolks;

                    } else {     
                      setGuias([]); 
                      setResultTitle("No haber resultados");
                    }
                      } catch (err) {
                        console.log(err);
                        setLoading(false);
                      }
                    }
            } catch (err) {
              console.log(err);
              setLoading(false);
            }
        } 
  } catch (err) {
    console.log(err);
    setLoading(false);
  }
}, []);

const getOtro = useCallback(async (idD) => {
  setLoading(true);
  try {
    let isLoggedIn = await authenticated();
    const { data } = await client.graphql({
      query: getAutomov,
      variables: {
        id: idD },
      authMode: isLoggedIn ? "userPool" : "iam"        
      }
    );
    if (data.getAutomov) {
    const boolks = data.getAutomov;
    setLoading(false);
    return boolks;
  } else {
        try {
          let isLoggedIn = await authenticated();
          const { data } = await client.graphql({
            query: getRock,
            variables: {
              id: idD },
            authMode: isLoggedIn ? "userPool" : "iam"              
            }
          );
            if (data.getRock) {
            const boolks = data.getRock;
            setLoading(false);
            return boolks;
                    }
                    try {
                      let isLoggedIn = await authenticated();
                      const { data } = await client.graphql({
                        query: getTambien,
                        variables: {
                          id: idD },
                        authMode: isLoggedIn ? "userPool" : "iam"
                        }
                      );
                        if (data.getTambien) {
                        const boolks = data.getTambien;
                        setLoading(false);
                        return boolks;
                                }
                                  else {     
                                          setOtros([]); 
                                          setResultTitle("No haber resultados");
                                        }
                                      } catch (err) {
                                        console.log(err);
                                        setLoading(false);
                                      }
                  } catch (err) {
                  console.log(err);
                  setLoading(false);
                }
              }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
}, []);

const getEvento = useCallback(async (idD) => {
  setLoading(true);
  try {
    let isLoggedIn = await authenticated();
    const { data } = await client.graphql({
      query: getEventos,
      variables: {
        id: idD },
        authMode: isLoggedIn ? "userPool" : "iam"
      }
    );
    if (data.getEventos) {
    const boolks = data.getEventos;
    setLoading(false);
    return boolks;
  } else { 
        setBooks([]); 
        setResultTitle("No haber resultados");
      }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
}, []);

const getVenta = useCallback(async (idD) => {
  setLoading(true);
  try {
    let isLoggedIn = await authenticated();
    const { data } = await client.graphql({
      query: getVentas,
      variables: {
        id: idD },
        authMode: isLoggedIn ? "userPool" : "iam"
      }
    );
    if (data.getVentas) {
    const boolks = data.getVentas;
    setLoading(false);
    return boolks;
  } else { 
        setVentas([]); 
        setResultTitle("No haber resultados");
      }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
}, []);


   useEffect(() => {
    fetchBooks();
   }, [searchTerm, searchTermRev, fetchBooks]);

  return (
    <BookContext.Provider value={{ books, revistas, presents, ventas, guias, otros, loading, resultTitle, criterio, idioma, tablaEdit, setIdioma, setCriterio, fetchPresent, fetchOtros, fetchLibros, fetchRevistas, fetchGuias, getBook, getGuia, getOtro, getEvento, getVenta, getRevista, setResultTitle, setSearchTerm, setSearchTermRev, fetchBooks, setTablaEdit }}>
      {children}
    </BookContext.Provider>
  );
};

export { BookContext, AppProvider };
