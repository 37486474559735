import { useContext } from 'react';
import { BookContext } from '../../../context/books';
import { menuItemsDataEsp } from "../menuItemsDataEsp";
import { menuItemsDataEng } from "../menuItemsDataEng";
import MenuItems from "./MenuItems";

const Navbar = () => {
  const depthLevel = 0;
  const { idioma } = useContext(BookContext);
  switch (idioma) {
    case "español":
  return (
    <nav className="desktop-nav">
      <ul className="menus">
        {menuItemsDataEsp.map((menu, index) => {
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </nav>
  );
  case "english":
    return (
      <nav className="desktop-nav">
        <ul className="menus">
          {menuItemsDataEng.map((menu, index) => {
            return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
          })}
        </ul>
      </nav>
    );
    default:
      break;
  }
  };

export default Navbar;