import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { BookContext } from '../../context/books';
import "./OtrosList.css";
import coverImg from "../../images/cover_not_found.jpg";
import config from '../../aws-exports';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const Otros = (otros) => {
    const { idioma } = useContext(BookContext);
    switch (idioma) {
      case "español":
    return (
        <div className="book-item flex flex-column flex-sb">
          <Link to = {`/otros/id/${otros.id}`} {...otros}>
            <div className="book-item-img">
                <img src = {otros.portada ? `https://${bucket}.s3.${region}.amazonaws.com/imgaut/${otros.portada}` : coverImg} alt = "cover" />
            </div>
          </Link>
            <div className="book-item-info text-center">
                <Link to = {`/otros/id/${otros.id}`} {...otros}>
                    <div className="book-inte-info-item title fw-7 fs-18">
                        <span>{otros.titulo}</span>
                    </div>
                </Link>
                <div className="book-inte-info-item author fs-15">
                    <span className="text-capitalized fw-7">Autor: </span>
                    <span>{otros.autor}</span>
                </div>
                <div className="book-inte-info-item author fs-15">
                    <span className="text-capitalized fw-7">Año de edición: </span>
                    <span>{otros.anoedicion}</span>
                </div>
               <Link to = {`/otros/id/${otros.id}`} {...otros}>
                <div className="book-inte-info-item author fw-3 fs-09">
                <span className="fw-3 fs-09">(Click para mas datos)</span>
                </div>
                </Link>
            </div>
        </div>
    )
    case "english":
        return (
            <div className="book-item flex flex-column flex-sb">
              <Link to = {`/otros/id/${otros.id}`} {...otros}>
                <div className="book-item-img">
                    <img src = {otros.portada ? `https://${bucket}.s3.${region}.amazonaws.com/imgaut/${otros.portada}` : coverImg} alt = "cover" />
                </div>
              </Link>
                <div className="book-item-info text-center">
                    <Link to = {`/otros/id/${otros.id}`} {...otros}>
                        <div className="book-inte-info-item title fw-7 fs-18">
                            <span>{otros.titulo}</span>
                        </div>
                    </Link>
                    <div className="book-inte-info-item author fs-15">
                        <span className="text-capitalized fw-7">Author: </span>
                        <span>{otros.autor}</span>
                    </div>
                    <div className="book-inte-info-item author fs-15">
                        <span className="text-capitalized fw-7">Edition's year: </span>
                        <span>{otros.anoedicion}</span>
                    </div>
                   <Link to = {`/otros/id/${otros.id}`} {...otros}>
                    <div className="book-inte-info-item author fw-3 fs-09">
                    <span className="fw-3 fs-09">(Click for more info)</span>
                    </div>
                    </Link>
                </div>
            </div>
        )    
        default:
            break;
}
}

export default Otros;